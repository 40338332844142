import { queryStringToObject } from 'client/helpers/queryStringToObject';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface IProps {
  navList: IData[];
}

interface IData {
  title: string;
  link: string;
  list?: IData[];
}

const Navbar: React.FC<IProps> = ({ navList }) => {
  const location = useLocation();

  const getIsCurrentLink = (str: string) =>
    str === `${location.pathname}${location.search || ''}` ||
    (location.search &&
      queryStringToObject(location.search)['product-type'] &&
      queryStringToObject(str)['product-type'] &&
      queryStringToObject(location.search)['product-type'] ===
        queryStringToObject(str)['product-type']);

  return (
    <nav className="navbar navbar-expand-lg  text-primary align-items-center justify-content-start bg-white">
      <ul style={{ listStyle: 'none' }} className=" d-flex align-content-center mb-0 pl-0">
        {navList.map((e) => (
          <li
            key={e.title}
            title={e.title.toLowerCase()}
            className={`h4 mx-2 my-auto ${
              e.list ? 'position-relative dropdown-toggle  dropdown-title' : ''
            } `}
          >
            {getIsCurrentLink(e.link) ? (
              <u>
                <Link to={e.link}>{e.title}</Link>
                {e.list && (
                  <ul className="h4  dropdown-menu px-2 rounded">
                    {e.list.map((el) => (
                      <Link to={el.link}>
                        <span className="py-2  text-underline">{el.title}</span>
                      </Link>
                    ))}
                  </ul>
                )}
              </u>
            ) : (
              <>
                <Link to={e.link}>{e.title}</Link>
                {e.list && (
                  <ul className="h4  dropdown-menu px-2 rounded">
                    {e.list.map((el) => (
                      <Link to={el.link}>
                        <span className="py-2  text-underline">{el.title}</span>
                      </Link>
                    ))}
                  </ul>
                )}
              </>
            )}
          </li>
        ))}
        <li title="details de la réservation" className="h4 mx-2 my-auto">
          <a href="https://kepler.mypgi.fr" target="__blank">
            <button className=" btn rounded  btn-primary border-0" type="button">
              Espace B2B
            </button>
          </a>
        </li>
        <li title="details de la réservation" className="h4 mx-2 my-auto">
          <Link to="/checking-reservation-details">
            <img
              src="/assets/images/home/person.png"
              alt="check reservation"
              title="check reservation"
              width={35}
              height={35}
            />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
