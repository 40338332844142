import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

interface ILinks {
  title?: string;
  to: string;
}
interface IProps {
  isLoading: boolean;
  breadcrumbs?: ILinks[];
}
// TODO:share button and save button is good to make it
const Breadcrumbs: React.FC<IProps> = ({ breadcrumbs, isLoading }) => (
  <div className="container container-xxl" data-testid="Breadcrumbs">
    {!isLoading ? (
      <div className="row align-items-lg-center">
        <div className="col-lg mb-2 mb-lg-0">
          {/* Breadcrumb */}
          <nav aria-label="breadcrumb">
            <ol
              data-testid="BreadcrumbsList"
              className="breadcrumb breadcrumb-no-gutter font-size-1 mb-0"
            >
              {breadcrumbs?.map((el) => (
                <li key={`${el.title}`} className="breadcrumb-item">
                  <Link to={el.to.toLowerCase()}>{el.title}</Link>
                </li>
              ))}
            </ol>
          </nav>
          {/* End Breadcrumb */}
        </div>
        <div className="col-lg-auto">
          <Link className="btn btn-sm btn-ghost-secondary" to="#">
            <i className="far fa-heart mr-2" /> Save
          </Link>
          <Link className="btn btn-sm btn-ghost-secondary" to="#">
            <i className="fas fa-share-alt mr-2" /> Share
          </Link>
        </div>
      </div>
    ) : (
      <Loader />
    )}
  </div>
);

export default Breadcrumbs;
