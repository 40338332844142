import Select from 'client/components/Form/Select';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import React from 'react';
import { ISearchCriteriaQuery } from 'types/ISearchCriteriaQuery';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
  productSearchCriteriaValue?: ISearchCriteriaQuery;
}

const FilterContentDestination: React.FC<IProps> = ({
  onChange,
  value,
  productSearchCriteriaValue,
  
}) => {
  const topDestinations = productSearchCriteriaValue?.country?.map((el) => ({
    
    label: upperCaseFirstLetter(el.label),
    value: upperCaseFirstLetter(el.label),
    
  }));


 const sortedCountry = topDestinations.sort((a,b) => a.label.localeCompare(b.label) );

return (
  
    <div className="d-flex flex-column p-3 w-100 rounded">
      <h3>TOP DESTINATIONS</h3>

      <div className="d-flex flex-row flex-wrap border-bottom container">
        
        {topDestinations?.map((el) => (
          
          <button
            type="button"
            key={el.label}
            className={`active-btn-hover btn ${value === el.label ? 'btn-success' : ''}`}
            onClick={() => onChange?.(el.label)}
          >
       
            {el.label}
          </button>
        ))}
      </div>
      <div className="d-flex flex-row text-dark my-2 mt-2 container w-100">
        <Select
          onChange={(value) => onChange?.(value as string)}
          options={topDestinations || []}
          selectClassName="w-100 p-3"
          isSearchable
        />
      </div>
    </div>
  );
};

export default FilterContentDestination;
