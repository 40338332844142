import { useTranslation } from 'react-i18next';

export const AscDescOptions = () => {
  const [t] = useTranslation();

  return [
    { label: t('FilterProducts.ascendant'), value: 'asc' },
    { label: t('FilterProducts.descendant'), value: 'desc' },
  ];
};
