import Select from 'client/components/Form/Select';
import { upperCaseFirstLetter } from 'client/helpers/format/upperCaseFirstLetter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISearchCriteriaQuery } from 'types/ISearchCriteriaQuery';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
  name?: string;
  productSearchCriteriaValue?: ISearchCriteriaQuery;
}

const FilterContentFrom: React.FC<IProps> = ({
  onChange,
  value,
  productSearchCriteriaValue,
}) => {
  const fromOptions = productSearchCriteriaValue?.from.map((el) => ({
    label: upperCaseFirstLetter(el.label),
    value: upperCaseFirstLetter(el.label),
  }));
  const [t] = useTranslation();

  return (
    <div className="d-flex flex-column p-3">
      <h3>{t('FilterHomePage.airports')}</h3>

      <div className="d-flex flex-row flex-wrap border-bottom">
        {fromOptions?.slice(0, 4).map((el) => (
          <button
            type="button"
            key={el.label}
            className={`btn ${value === el.label ? 'btn-success' : ''} ${
              value === el.label && 'active-btn'
            } active-btn-hover`}
            onClick={() => onChange?.(el.label)}
          >
            {el.label}
          </button>
        ))}
      </div>
      <div className="d-flex flex-row text-dark">
        <Select
          onChange={(value) => onChange?.(value as string)}
          options={fromOptions || []}
          selectClassName="w-100 p-3 mb-5"
          isSearchable
        />
      </div>
    </div>
  );
};

export default FilterContentFrom;
